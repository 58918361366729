<template>

  <div class="selesDetail">
    <div class="headerBox">
      <div  class="headerBox_operate">
        <template v-if="orderDetail.entry_status_id!=20">
             <el-button type="primary" icon="el-icon-plus" size="mini" @click="parentMethod(1)" v-if='AuthpermissionsData[setPermissionsData.createDetail.toLowerCase()]||AuthpermissionsData[setPermissionsData.editEntry.toLowerCase()]'>保存</el-button>
             <el-button  icon="el-icon-folder-add" size="mini" @click="parentMethod(2)" v-if='AuthpermissionsData[setPermissionsData.createDetail.toLowerCase()]||AuthpermissionsData[setPermissionsData.editEntry.toLowerCase()]'>保存并新增</el-button>
        </template>
        <template v-else>
              <el-button type="primary" icon="el-icon-plus" size="mini" @click="parentMethod(14)" v-if='AuthpermissionsData[setPermissionsData.createDetail.toLowerCase()]||AuthpermissionsData[setPermissionsData.editEntry.toLowerCase()]'>新建</el-button>
        </template>

        <el-button size="mini" icon='el-icon-check' v-if="orderDetail.entry_status_id==10" @click="parentMethod(3)">审核</el-button>
        <el-button size="mini" icon='el-icon-check' v-if="orderDetail.entry_status_id==20&&AuthpermissionsData[setPermissionsData.reverseCheckEntry.toLowerCase()]" @click="parentMethod(13)">反审核</el-button>
            <el-dropdown >

                <el-button size="mini" style="margin:0 10px;">
                  导入<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">

                  <el-upload v-if="AuthpermissionsData[setPermissionsData.importGoodsDataByUploadFile.toLowerCase()]"
                  :action="
                    baseUrl + '/store/goods.EntryCtl/importGoodsDataByUploadFile'
                  "
                  :data="{
                    goods_type_id:detailQuery.goods_type_id,
                    goods_number_type:detailQuery.goods_number_type,
                    getFileTitle: 1,
                  }"
                  name="datafile"
                  :headers="{
                    authorization: token,
                  }"
                  :show-file-list="false"
                  :on-success="handleGoodsSuccess"
                  style="
                    margin:0 10px;
                    display:inline-block;
                  "
                >
                  <el-dropdown-item >导入列表</el-dropdown-item>
            </el-upload>

              <el-dropdown-item @click.native="parentMethod(12)" v-if="AuthpermissionsData[setPermissionsData.uploadGoodsPicByZip.toLowerCase()]">导入货品图片</el-dropdown-item>
              <el-dropdown-item @click.native="parentMethod(11)" v-if="AuthpermissionsData[setPermissionsData.getImportFile.toLowerCase()]">下载导入模板</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        <el-button size="mini" icon="el-icon-top" @click="parentMethod(8)" v-if="AuthpermissionsData[setPermissionsData.exportExcel.toLowerCase()]">导出</el-button>
          <el-dropdown v-if="AuthpermissionsData[setPermissionsData.entryPrintDetail.toLowerCase()]">
            <el-button size="mini" style="margin:0 10px 0 0px;" @click.native="parentMethod(5)">
              打印
              <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="parentMethod(9)">打印设置</el-dropdown-item>
              <el-dropdown-item @click.native="parentMethod(10)">打印标签</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

        <el-button icon="el-icon-s-fold" size="mini" @click="parentMethod(6)">单据列表</el-button>
        <el-button type="danger" icon="el-icon-close" size="mini" @click="parentMethod(7)" >关闭</el-button>
      </div>
    </div>
     <div class="mian_top" ref="top">
      <div class="headerBox">
        <div class="headerBox_title">
          <span>基础信息</span>
          <div class="">
            <el-form :model="formQuery" :inline="true" label-position="left" style="padding-top:10px" ref="formQuery" :disabled="formDisabled">
              <el-form-item  label="单据编号" label-width="100px" class="queryCondition">
                <el-input class="queryCondition_input"  style="200px"  v-model="formQuery.input" disabled></el-input>
              </el-form-item>
              <el-form-item  label="单据日期" label-width="100px" class="queryCondition">
                <el-input class="queryCondition_input"  style="200px"  v-model="formQuery.input" disabled></el-input>
              </el-form-item>
              <el-form-item  label="会员名称" label-width="100px" class="queryCondition">
                <el-input class="queryCondition_input"  style="200px"  v-model="formQuery.input" disabled></el-input>
              </el-form-item>
              <el-form-item  label="门店" label-width="100px" class="queryCondition">
                <el-input class="queryCondition_input"  style="200px"  v-model="formQuery.input" disabled></el-input>
              </el-form-item>
              <el-form-item  label="审核状态" label-width="100px" class="queryCondition">
                <el-input class="queryCondition_input"  style="200px"  v-model="formQuery.input" disabled></el-input>
              </el-form-item>
              <el-form-item  label="单据备注" label-width="100px" class="queryCondition">
                <el-input class="queryCondition_input"  style="200px"  v-model="formQuery.input" disabled></el-input>
              </el-form-item>
             </el-form>
          </div>
        </div>
        <div class="marketInfo">

        </div>
      </div>
    </div>
    <div>
      <div class="marketInfo_tab_name marketInfo_tab_li_i">
        <div class="marketInfo_tab_li">货品信息<span>(共10件)</span></div>
        <div class="marketInfo_tab_li">货品退货<span>(共1件)</span></div>
        <div class="marketInfo_tab_li">旧料回收<span>(共10件)</span></div>
        <div class="marketInfo_tab_li">旧料赎回<span>(共10件)</span></div>
      </div>
      <div class="marketInfo_tab_cont">
        <div class="marketInfo_tab_lab"></div>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "good-storage";
import Cookies from "js-cookie";
import {getMyAuth} from "@/api/authority/config";
import {permissionsData} from "@/api/authority/permissions";
import {getDefaultFormValue} from "@/api/components/index.js";
import {
  templateList,
  getTemplateInfo,
  addTemplateInfo,
  reverseCheckEntry,
  importGoodsDataByUploadFile,
  configList,
  getEntryInfo,
  getEntryStatus,
} from "@/api/goods/goodsWarehousing/warehousing";
export default {
  components:{},
  data() {
    return {
      clientHeight: document.documentElement.clientHeight, //实时屏幕高度
      formDisabled:false,
      formQuery:{
        input:"",
        state:""
      },
      editTable:"",
      editTableHeight:240,
      detailQuery:{},
      templateList: [],
      templateTotal: 0,
      token:'',
      baseUrl: "",
      templateVisiable:false,
      tableVisiable:false,
      loading:false,
      templateName: "新增表头模板",
      template_name: "",
      fieldList: [],
      goodsinfo_map: {},
      orderDetail:{},
      setPermissionsData:{},//权限配置对照数据
      AuthpermissionsData:{},//系统权限数据
      h:''
    };
  },
  created() {
    this.detailQuery=this.$route.query
    this.setPermissionsData=permissionsData.godownEntry;//获取入库列表列表权限对照表
    // this.getMyAuth();
    this.token = storage.session.get("token") || Cookies.get("token");
    const env = process.env.NODE_ENV;
    const host = window.location.host;
    //下面文本类型，输入框类型不用管，只用于展示

    if (env === "development") {
      this.baseUrl = "http://api.dev.etouch.vip";
    } else {
       if (host === "edianbao.dev.etouch.vip") {
        this.baseUrl = "http://api.dev.etouch.vip";
      }
      if (host === "edianbao.test.etouch.vip") {
        this.baseUrl = "https://api.test.etouch.vip";
      }
      if (host === "www.etouch.top") {
        this.baseUrl = "https://api.etouch.top";
      }
      if (host === "edianbao.pretest.etouch.vip") {
        this.baseUrl = "https://api.pretest.etouch.vip";
      }
      if (host === "edianbao.test2.etouch.vip") {
        this.baseUrl = "http://api.test2.etouch.vip";
      }
    }
    console.log(this.baseUrl)
  },
  mounted () {



},
  methods: {//表单为空校验
    setRules(item){
      // if(item.is_compulsory==1){
      //   return  {required:true, message:item.field_text+'不能为空', trigger: blur}
      // }

    },
     //入库单详情操作按钮父组件调用子组件方法
    parentMethod(type){

    },
    //设置表单默认值
    getDefaultFormValue(){
      getDefaultFormValue({type:'entry'}).then((res) => {
        let list=[{field:"entry_number",value:"-"},{field:"entry_status",value:"未审核"}];
        const mergeList = [...res.data.listMap, ...list];
        console.log(mergeList)
        this.$refs.basisInfoChild.setDate(1,mergeList)
        console.log(res);
      })
      //
    },
    //设置表单的状态
    getformSatus(status){
      this.$refs.basisInfoChild.formDisabledfun(status)
    },
     //获取系统权限
    getMyAuth(){
      getMyAuth({}).then((res) => {
        console.log(res);
        this.AuthpermissionsData=res.data;
        this.getEntryStatus();
      })
    },

    getEntryStatus(){
      getEntryStatus({}).then((res) => {
        if(res.code==1){
          this.options=res.data;
        }
      })
    },

     /*
      *  获取入库单详情
      */
      getEntryInfo(entry_id) {
        getEntryInfo({ entry_id: entry_id }).then((res) => {
          if (res.code === 1) {
            this.orderDetail=res.data
          }
        });
      },

    //计算表格高度属性
    tabstyle(){
      let h =this.h;
      let topHeight = this.$refs.top.offsetHeight;
      let shight=h-topHeight - 160+"px"
      this.mian_tabStyle={height:shight};
      this.editTable={height: shight};
      this.editTableHeight=this.h - topHeight - 240;
      if(this.editTableHeight<240){
        this.editTableHeight=240;
      }
    },



     /*
     *  导入货品数据
     */
    handleGoodsSuccess(res, file) {
       this.getAttrList2()
      if (res.code === 1) {
        this.templateVisiable = true;
        this.getTemplateList();
        this.customerTitle = res.data.customerTitle;
        this.downFiledList = res.data.customerTitle.map((item, index) => {
          var info = {};
          info.name = String(item);
          info.disabled = false;
          return info;
        });
        console.log(this.downFiledList, "downFiledList");
      } else {
        this.$message({
          type: "error",
          message: res.msg,
        });
      }
    },

      /*
     *  新增配置表头模板
     */
    handleConfigTemplate(type) {
      if (this.templateName === "新增表头模板") {
        if (this.template_name === "") {
          this.$message({
            type: "error",
            message: "请填写模板名称",
          });
        } else {
          const data = {
            goods_type_id:this.detailQuery.goods_type_id,
            template_name: this.template_name,
            goodsinfo_map: [this.goodsinfo_map],
          };

          this.loading = true;
          addTemplateInfo(data)
            .then((res) => {
              if (res.code === 1) {
                if (type === 10) {
                  this.tableVisiable = false;
                  this.templateVisiable = true;
                  this.goodsinfo_map = {};
                  this.loading = false;
                  this.getTemplateList();
                } else if (type === 20) {
                  this.goods_type_import_id = res.data;
                  this.handleSureTable(20);
                }
              } else {
                this.loading = false;
              }
            })
            .catch((res) => {
              this.loading = false;
            });
        }
      }
    },

     /*
     *  获取品类字段
     */
    getAttrList2(id) {
      const data = {
        goods_type_id:this.detailQuery.goods_type_id,//obj.goods_type_id,
        fun:'EntryDetails',
        is_all:0,
        mode:'column'
      };
      configList(data).then((res) => {
        let newFiled = {};
        let len = res.data.length;
        this.fieldList = res.data;
        let newArr = res.data.map((item, index) => {
          item.prop = String(item.field);
          item.label = String(item.field_text);
          newFiled["index"] = 0;
          newFiled[item.field] = "";
          this.filed[item.field] = "";
          return item;
        });
        const newList = [];
        newList.push(newFiled);
        this.goodsinfo_map = { ...newFiled };

        this.tableList = [...newList];
        this.rowList = [...newArr];
        this.batchEditForm = { ...this.filed };
      });
    },


     /*
     *  删除表头模板
     */
    handleDelTemplate(data) {
      const info = {
        goods_type_import_id: data.goods_type_import_id,
        type: "delete",
      };
      this.$confirm("确定要删除该表头模板吗？删除后将无法恢复。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        addTemplateInfo(info).then((res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "删除成功！",
            });
            this.getTemplateList();
          }
        });
      });
    },

    //增加模板
    handelAddTemplate() {
      this.fieldList.forEach((item) => {
        this.downFiledList.forEach((items) => {
          const name =
            items.name.indexOf("*") != -1
              ? items.name.split("*")[1]
              : items.name;
          if (item.attr_name === name) {
            this.goodsinfo_map[item.field] = item.attr_name;
          }
        });
      });
      this.tableVisiable = true;
      this.templateVisiable = false;
      this.loading = false;
      this.template_name = "";
      this.templateName = "新增表头模板";
    },

     cancelTemplateTable() {
      this.templateVisiable = false;
    },

     handleCurrentChange(val) {
      templateList({ page: val }).then((res) => {
        if (res.code === 1) {
          this.templateList = res.data.list;
          this.templateTotal = res.data.total;
        }
      });
    },

     handleDisableFileList() {
      for (let item in this.goodsinfo_map) {
        this.downFiledList = this.downFiledList.map((item, index) => {
          if (index === Number(this.goodsinfo_map[item])) {
            item.disabled = true;
          }
          return item;
        });
      }
    },

    /*
     *  将品类多维数组转为对象
     */
    cancelTable() {
      this.tableVisiable = false;
      this.templateVisiable = true;
      this.loading = false;
      this.goodsinfo_map = {};
      this.getTemplateList();
    },

  },
};
</script>

<style lang="less" >
  .selesDetail{
    .el-input .el-input__inner {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
    }
    .queryCondition{
      margin-bottom: 12px;
    }
    .el-form-item__error{
      padding: 0;
    }
     .el-form-item__content{
        line-height: 32px;
      }
      .el-form-item__label{
        line-height: 32px;
      }
      .el-input__icon{
        line-height: 32px;
      }
    .datePicker{
      height: 40px;
      .el-input__inner {
        font-size: 14px;
        height: 32px;
        line-height: 32px;
        padding: 0;
        width:220px;
      }
      .el-icon-date{
        margin-left: 6px;
      }

      .el-range-input{

      }


    }
    .v-align-t{
      .vxe-cell{
        padding-top:6px;
      }
    }
    .el-dialog__body{
      padding-top:8px;
    }
    .mian_footer{
        position: sticky;
        left: 0px;
        bottom: 0px;
        background: #fff;
        width: calc(100% );
        padding-bottom: 10px;
       .mian_page{
        padding: 15px 0;
        background: #fff;
        margin-right: 20px;
        display: flex;
        justify-content: flex-end;

        .el-pagination.is-background .el-pager li {
            border-radius: 2px;
            font-weight: normal;
            background-color:#FFFFFF;
            border: 1px solid #D9D9D9;
            width: 32px;
            height: 32px;
            line-height: 32px;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #409EFF;
            color: #FFF;
        }
        .el-pagination.is-background .btn-prev {
            border-radius: 2px;
            border: 1px solid #ddd;
            background: white;
            height: 32px;
            line-height: 32px;
        }
        .el-pagination.is-background .btn-next {
            border-radius: 2px;
            border: 1px solid #ddd;
            background: white;
            height: 32px;
            line-height: 32px;
        }

      }
    }


  }

</style>
<style lang="less" scoped>
.marketInfo_tab_name{
  display: flex;
  background: #fff;
  .marketInfo_tab_li{
    width: 150px;
    padding: 12px 0;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #595959;
    span{
      font-size: 12px;
    }
  }
}
.selesDetail{
  margin: 0 10px;
  /deep/ .el-form-item__label:before {
      content: '*';
      color: #fff;
      margin-right: 4px;
  }
}
.el-icon-setting{
  font-size: 18px;
  cursor: pointer;
}

.el-form--inline .el-form-item__label {
    float: none;
    display: inline-block;
}
.godownEntryListDetails{
  margin: 5px 10px;
  height: calc(100vh - 160px);
  padding-bottom: 60px;
  position: relative;
}
  .mian_top{
    background: #fff;
    padding: 12px 20px;
    padding-top: 12px;
    margin-bottom: 12px;
    height: 118px;
    .headerBox{
        .headerBox_title{
          font-size: 16px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #595959;
          .headerBox_right{
            float: right;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #595959;
            display: flex;
            align-items: center;
            cursor:pointer;
            span{
              margin-left:8px;
            }
          }
        }
    }
  }
  .headerBox{
    overflow: hidden;

    .headerBox_operate{
      float: right;
      margin-bottom: 12px;
      margin-top: 2px;
    }

  }

</style>
