//页面详细权限配置表
let permissionsData={
  'godownEntry':{//入库单权限
    'create':'store/goods.EntryCtl/create',//创建入库记录
    'createonemore':'store/goods.EntryCtl/addGoodsOnemore',//一码多货 追加
    'Delete':'store/goods.EntryCtl/batchDeleteReverseEntry',//删除入库记录
    'batchUpdateState':'store/goods.EntryCtl/batchUpdateState',//确认审核状态
    'entryPrint':'store/goods.EntryCtl/entryPrint',//打印
    'export':'store/goods.EntryCtl/export',//导出表格

    //详情页
    'createDetail':'store/goods.EntryCtl/create', //新增
    'editEntry':'store/goods.EntryCtl/editEntry',
    'reverseCheckEntry':'store/goods.EntryCtl/reverseCheckEntry', //反审核
    'importGoodsDataByUploadFile':'store/goods.EntryCtl/importGoodsDataByUploadFile', //导入货品
    'uploadGoodsPicByZip':'store/goods.Goods/uploadGoodsPicByZip',  //导入货品图片
    'getImportFile':'store/goods.EntryCtl/getImportFile', //下载导入模板
    'exportExcel':'store/goods.EntryCtl/exportExcel', //导出    
    'entryPrintDetail':'store/goods.EntryCtl/entryPrint', //打印
    'batchExportEntryGoods':'store/goods.EntryCtl/batchExportEntryGoods', //导出详情

    'setConfig':'store/Config.Index/set', //设置按钮
   
  }
}
export {
  permissionsData
}
