import { post } from '@/utils/request.js';

// 获取配置选项
// Entry： 入库单列表 EntryDetails： 入库单详情
// 列表：column 表单：form 搜索：search
const configuration = (data) => post({
  url: '/store/Config.Index/index',
  data:data,
});

// 获取远程搜索的值
const remoteSet = (data) => post({
  url: '/store/common.select/'+data.select_code,
  data:{search:data.search},
});
// 获取关联下拉的值
const associatedSet = (data,select_code) => post({
  url: '/store/common.select/'+select_code,
  data:data,
});

// 保存配置
// 列表：column 表单：form 搜索：search
const setConfig = (data) => post({
  url: '/store/Config.Index/set',
  data:data,
});

// 配置初始化
const configRestore = (data) => post({
  url: '/store/Config.Index/restore',
  data:data,
});

// 表单默认值
const getDefaultFormValue = (data) => post({
  url: '/store/common.Select/getDefaultFormValue',
  data:data,
});


// 获取分店员工
const getShopEmployeeValue = (data) => post({
  url: '/store/common.select/getStoreUser',
  data:data,
});

// 获取分店仓库
const getShopWarehouseFormValue = (data) => post({
  url: '/store/common.select/getWarehouseListV2',
  data:data,
});
export {
  configuration,
  remoteSet,
  setConfig,
  configRestore,
  getDefaultFormValue,
  associatedSet,
  getShopEmployeeValue,
  getShopWarehouseFormValue
};

