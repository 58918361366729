import { render, staticRenderFns } from "./selesDetail.vue?vue&type=template&id=4b99dcac&scoped=true&"
import script from "./selesDetail.vue?vue&type=script&lang=js&"
export * from "./selesDetail.vue?vue&type=script&lang=js&"
import style0 from "./selesDetail.vue?vue&type=style&index=0&id=4b99dcac&prod&lang=less&"
import style1 from "./selesDetail.vue?vue&type=style&index=1&id=4b99dcac&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b99dcac",
  null
  
)

export default component.exports